import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4198837c = () => interopDefault(import('..\\pages\\adcetris-landing.vue' /* webpackChunkName: "pages_adcetris-landing" */))
const _e357a7da = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _61b9084c = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _59f67817 = () => interopDefault(import('..\\pages\\re-enroll\\index.vue' /* webpackChunkName: "pages_re-enroll_index" */))
const _363f0c14 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _4e8b7817 = () => interopDefault(import('..\\pages\\selection.vue' /* webpackChunkName: "pages_selection" */))
const _85326ec8 = () => interopDefault(import('..\\pages\\tivdak-landing.vue' /* webpackChunkName: "pages_tivdak-landing" */))
const _78db68e8 = () => interopDefault(import('..\\pages\\tukysa-landing.vue' /* webpackChunkName: "pages_tukysa-landing" */))
const _abdc5a6e = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _51f26f18 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _51d64016 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _519de212 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _5181b310 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _0bed03b5 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _c20d8fc2 = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _6f9b8976 = () => interopDefault(import('..\\pages\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_need-a-card_patient-information" */))
const _5b4022a4 = () => interopDefault(import('..\\pages\\need-a-card\\provider-information.vue' /* webpackChunkName: "pages_need-a-card_provider-information" */))
const _812b92d6 = () => interopDefault(import('..\\pages\\need-a-card\\success.vue' /* webpackChunkName: "pages_need-a-card_success" */))
const _634c5b6d = () => interopDefault(import('..\\pages\\patient\\patientSearch.vue' /* webpackChunkName: "pages_patient_patientSearch" */))
const _1a202792 = () => interopDefault(import('..\\pages\\re-enroll\\eligibility.vue' /* webpackChunkName: "pages_re-enroll_eligibility" */))
const _b129bbe2 = () => interopDefault(import('..\\pages\\re-enroll\\enrollment.vue' /* webpackChunkName: "pages_re-enroll_enrollment" */))
const _61831b67 = () => interopDefault(import('..\\pages\\re-enroll\\member-page.vue' /* webpackChunkName: "pages_re-enroll_member-page" */))
const _39bd542e = () => interopDefault(import('..\\pages\\re-enroll\\patient-information.vue' /* webpackChunkName: "pages_re-enroll_patient-information" */))
const _60d89eca = () => interopDefault(import('..\\pages\\re-enroll\\provider-information.vue' /* webpackChunkName: "pages_re-enroll_provider-information" */))
const _5980d788 = () => interopDefault(import('..\\pages\\re-enroll\\success.vue' /* webpackChunkName: "pages_re-enroll_success" */))
const _4a8e0ddd = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adcetris-landing",
    component: _4198837c,
    name: "adcetris-landing"
  }, {
    path: "/error",
    component: _e357a7da,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _61b9084c,
    name: "have-a-card"
  }, {
    path: "/re-enroll",
    component: _59f67817,
    name: "re-enroll"
  }, {
    path: "/rejection",
    component: _363f0c14,
    name: "rejection"
  }, {
    path: "/selection",
    component: _4e8b7817,
    name: "selection"
  }, {
    path: "/tivdak-landing",
    component: _85326ec8,
    name: "tivdak-landing"
  }, {
    path: "/tukysa-landing",
    component: _78db68e8,
    name: "tukysa-landing"
  }, {
    path: "/unlock",
    component: _abdc5a6e,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _51f26f18,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _51d64016,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _519de212,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _5181b310,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _0bed03b5,
    name: "errors-500"
  }, {
    path: "/need-a-card/eligibility",
    component: _c20d8fc2,
    name: "need-a-card-eligibility"
  }, {
    path: "/need-a-card/patient-information",
    component: _6f9b8976,
    name: "need-a-card-patient-information"
  }, {
    path: "/need-a-card/provider-information",
    component: _5b4022a4,
    name: "need-a-card-provider-information"
  }, {
    path: "/need-a-card/success",
    component: _812b92d6,
    name: "need-a-card-success"
  }, {
    path: "/patient/patientSearch",
    component: _634c5b6d,
    name: "patient-patientSearch"
  }, {
    path: "/re-enroll/eligibility",
    component: _1a202792,
    name: "re-enroll-eligibility"
  }, {
    path: "/re-enroll/enrollment",
    component: _b129bbe2,
    name: "re-enroll-enrollment"
  }, {
    path: "/re-enroll/member-page",
    component: _61831b67,
    name: "re-enroll-member-page"
  }, {
    path: "/re-enroll/patient-information",
    component: _39bd542e,
    name: "re-enroll-patient-information"
  }, {
    path: "/re-enroll/provider-information",
    component: _60d89eca,
    name: "re-enroll-provider-information"
  }, {
    path: "/re-enroll/success",
    component: _5980d788,
    name: "re-enroll-success"
  }, {
    path: "/",
    component: _4a8e0ddd,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
